/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Contact = () => {
  return (
    <Layout>
      <SEO title="Fitness and Spirit- Contact" />
      <div className="container-main">
        <div className="container-main_div">
          <section className="text-gray-700 body-font">
            <div className="container px-4 pt-24 pb-24 mx-auto lg:px-4">
              <div className="flex flex-col w-full p-8 mx-auto mt-10 border rounded-lg lg:w-5/12 md:w-1/2 md:ml-auto md:mt-0">
                <h1 className="text-2xl font-bold text-gray-700 mb-10">
                  Contact
                </h1>
                <form
                  name="contact"
                  method="POST"
                  data-netlify="true"
                  action="/thank-you"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="relative ">
                    <input
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className="w-full px-4 py-2 mb-4 mr-4 text-base text-blue-700 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                  </div>

                  <div className="relative ">
                    <input
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className="w-full px-4 py-2 mb-4 mr-4 text-base text-blue-700 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                  </div>

                  <div className="relative ">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      className="w-full px-4 py-2 mb-4 mr-4 text-base text-blue-700 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                  </div>

                  <div className="relative ">
                    <textarea
                      type="message"
                      name="message"
                      placeholder="message"
                      className="w-full px-4 py-2 mb-4 mr-4 text-base text-blue-700 bg-gray-100 border-transparent rounded-lg focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                  </div>
                  <button
                    type="submit"
                    className="px-8 py-2  mt-6 font-semibold text-white transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-gradient-to-r from-blue-700 hover:from-blue-600 to-blue-600 hover:to-blue-700 focus:ring focus:outline-none"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
